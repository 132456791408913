<template>
    <common-form
            ref="form"
            url="web/beian/product"
            :edit="edit"
            :items="items"
            :before-request="beforeRequest"
            @success="$emit('success')">

        <template slot="commission">
            <a-table :columns="paraColumns" :data-source="commissionArray" bordered size="middle" :pagination="false">
                <template slot="price" slot-scope="text, record, index">
                    <a-input v-model="record.price" suffix="%"></a-input>
                </template>
            </a-table>
        </template>

        <template slot="commission1">
            <a-table :columns="paraColumns" :data-source="commissionArray1" bordered size="middle" :pagination="false">
                <template slot="price" slot-scope="text, record, index">
                    <a-input v-model="record.price" suffix="元"></a-input>
                </template>
            </a-table>
        </template>

        <template slot="commission2">
            <a-table :columns="paraColumns" :data-source="commissionArray2" bordered size="middle" :pagination="false">
                <template slot="price" slot-scope="text, record, index">
                    <a-input v-model="record.price" suffix=""></a-input>
                </template>
            </a-table>
        </template>

    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit: false,
                paraColumns: [
                    {
                        title: '用户类型',
                        dataIndex: 'name',
                        width:240
                    },
                    {
                        title: '分佣比例',
                        dataIndex: 'price',
                        scopedSlots: {customRender: 'price'},
                    },
                ],
                commissionArray:[
                    { name:"管理团队", price:"" ,key:"commissionM"},
                    { name:"陆志敏", price:"", key:"commissionP2" },
                    { name:"一级（介绍人的上级）", price:"",key:"commissionP1" },
                    { name:"介绍人", price:"", key:"commissionU" },
                ],
                commissionArray1:[
                    { name:"管理团队", price:"" },
                    { name:"陆志敏", price:"" },
                    { name:"介绍人", price:"" },
                ],
                commissionArray2:[
                    { name:"管理团队", price:"" },
                    { name:"陆志敏", price:"" },
                    { name:"所属上级", price:"" },
                    { name:"介绍人", price:"" },
                ],
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "input",
                        name: "名称",
                        key: "name",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "佣金",
                        key: "commission",
                        required: true,
                        suffix:"元/套"
                    },
                    {
                        type: "slot",
                        name: "分佣",
                        slot: "commission"
                    },
                    // {
                    //     type: "slot",
                    //     name: "一级分佣",
                    //     slot: "commission1"
                    // },
                    // {
                    //     type: "slot",
                    //     name: "二级分佣",
                    //     slot: "commission2"
                    // },
                    {
                        type: "checkbox",
                        name: "下架",
                        key: "disable",
                        hint: "产品下架后，微信端就无法看到，无法备案"
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                let data = JSON.parse(JSON.stringify(model))
                this.edit = model.id > 0
                if(this.edit){
                    data.commission/=100
                    this.commissionArray1[0].price = data.commissionM1
                    this.commissionArray1[1].price = data.commissionX1
                    this.commissionArray1[2].price = data.commissionU1
                    this.commissionArray2[0].price = data.commissionM2
                    this.commissionArray2[1].price = data.commissionX2
                    this.commissionArray2[2].price = data.commissionP
                    this.commissionArray2[3].price = data.commissionU2

                    this.commissionArray.forEach(item=>{
                        item.price = data[item.key]
                    })
                }else{
                    this.commissionArray.forEach(item=>{
                        item.price = ""
                    })
                }
                this.$refs.form.show(data)
            },
            beforeRequest(model){
                let data = JSON.parse(JSON.stringify(model))
                data.commission = parseInt(data.commission*100)
                // model.commissionM1 = this.commissionArray1[0].price
                // model.commissionX1 = this.commissionArray1[1].price
                // model.commissionU1 = this.commissionArray1[2].price
                // model.commissionM2 = this.commissionArray2[0].price
                // model.commissionX2 = this.commissionArray2[1].price
                // model.commissionP = this.commissionArray2[2].price
                // model.commissionU2 = this.commissionArray2[3].price

                this.commissionArray.forEach(item=>{
                    data[item.key]=parseInt(item.price||"0")
                })

                return data
            }
        }
    }
</script>
